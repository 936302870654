.password-pop-up {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 5;
  color: #0b4694;

  &__body {
    width: 19.3125rem;
    height: 15rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 3.3125rem 2.5rem 0 2.5rem;
    background: #ffffff;
    border: 0.125rem solid rgba(255, 255, 255, 0.3);
    box-shadow: 0rem 0.25rem 0.25rem rgba(11, 70, 148, 0.2);
    border-radius: 0.25rem;
    border: 1px solid #323232;
  }
  &__header {
    width: 100%;
    text-align: center;
    margin: 0 0 1.5rem 0;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.1875rem;
  }
  &__password {
    margin: 0 0 1.625rem 0;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.5rem;
    text-align: center;
  }

  &__button {
    width: 80%;
    height: 2.625rem;
  }
  &__button-text {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.1875rem;
    color: #ffffff;
  }
}
