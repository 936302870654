.transaction-history {
  padding: 1.5rem 1.1875rem 2.5rem 1.1875rem;
  color: #0b4694;

  &__filters {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.125rem 0.625rem;
    margin: 0 0 1.375rem 0;

    &-title {
      font-weight: 700;
      font-size: 0.875rem;
      line-height: 1.0625rem;
    }
    &-input {
      width: 100%;
      height: 2.5625rem;
    }
  }

  &__search-button {
    height: 2.7143rem;
    width: 74.25%;
    min-width: 15rem;
    margin: 2.5rem auto 2.8125rem auto;
  }
  &__search-button-text {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.1875rem;
    color: #ffffff;
  }

  &__filters-search {
    width: 100%;
    height: 2.5625rem;
    display: flex;
    position: relative;
  }

  &__result-wrapper {
    width: 94%;
    min-width: 20rem;
    margin: 0 auto 2.5rem auto;
  }
  &__users-header {
    height: 2.5rem;
    display: grid;
    grid-template-columns: 1.3fr 1.3fr 1fr 1fr;
    align-items: center;
    margin-bottom: 1rem;
    padding: 0 1rem;
    background: #ffffff;
    border: 0.125rem solid rgba(255, 255, 255, 0.3);
    border-radius: 0.25rem;
  }
  &__user-header-item {
    width: 100%;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: #0b4694;

    &_type {
      &_center {
        text-align: center;
      }
      &_right {
        text-align: right;
      }
    }
  }

  &__total {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 0.9rem;
  }
  &__total-item {
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__total-value {
    margin-left: 0.5rem;
    font-size: 1.1rem;
    font-weight: 700;
  }
}
