.menu-item {
  position: relative;
  width: 100%;
  min-width: 20.75rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.875rem;
  margin: 0 auto 0.5rem auto;
  color: #0b4694;
  background: #ffffff;
  font-weight: 700;
  font-size: 1rem;
  text-transform: capitalize;

  &__header-arrow {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: rotate(180deg);
    position: absolute;
    right: 0.9375rem;

    &-svg {
      width: 1rem;
      height: 1rem;
    }
    &_open {
      transform: rotate(0deg);
    }
  }

  &__element-list {
    width: 85%;
    min-width: 18.75rem;
    margin: 0 auto 0.5rem auto;
    &:last-child {
      padding-bottom: 0;
    }
  }

  &__element {
    width: 100%;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    border: 0.125rem solid rgba(255, 255, 255, 0.3);
    border-radius: 0.25rem;
    padding: 0 1rem 0 1.5625rem;
    margin-bottom: 0.25rem;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: #0b4694;
  }
  &__arrow {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: rotate(90deg);

    &-svg {
      width: 1rem;
      height: 1rem;
    }
  }
}
