.input-date-mobile {
  user-select: none;
  cursor: pointer;
  width: 100%;
  height: 100%;

  & .react-datepicker-wrapper {
    width: 100%;
    height: 100%;
  }
  & .react-datepicker__input-container {
    width: 100%;
    height: 100%;
  }
  & .react-datepicker__triangle {
    display: none;
  }

  &__custom-date-input {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.125rem 0 1.3125rem;
    background: #ffffff;
    border: 0.125rem solid rgba(255, 255, 255, 0.3);
    border-radius: 0.25rem;

    &-calendar {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &-svg {
        width: 1.3125rem;
        height: 1.3125rem;
      }
    }
    &-value {
      margin-right: auto;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.0625rem;
      color: #0b4694;
    }
  }

  &_valid &__custom-date-input {
    /* border: 1px solid green; */
  }
}
