.user-edit {
  padding: 0.875rem 1.25rem 2.5rem 1.25rem;
  color: white;

  &__info {
    width: 100%;
    margin: 0 auto 1.25rem auto;

    &-item {
      margin-bottom: 1.1875rem;
    }
    &-title {
      margin-bottom: 0.4375rem;
      font-weight: 700;
      font-size: 0.875rem;
      line-height: 1.0625rem;
      color: #0b4694;
    }
    &-value {
      height: 2.625rem;
      display: flex;
      align-items: center;
      background: #ffffff;
      border: 0.125rem solid rgba(255, 255, 255, 0.3);
      border-radius: 0.25rem;
      padding: 0 1.3125rem;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.0625rem;
      color: #0b4694;
    }
  }

  &__payment {
    width: 100%;
    margin: 0 auto 2.5rem auto;

    &-item {
      margin-bottom: 1.1875rem;
    }
    &-title {
      margin-bottom: 0.4375rem;
      font-weight: 700;
      font-size: 0.875rem;
      line-height: 1.0625rem;
      color: #0b4694;
    }
    &-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 74%;
      min-width: 15rem;
      height: 2.5rem;
      margin: 1.3125rem auto 0 auto;
    }
  }

  &__buttons {
  }
  &__buttons-bottom {
    display: flex;
    justify-content: space-between;
  }
  &__ban-button,
  &__unban-button {
    cursor: pointer;
    width: 47%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    height: 2.5rem;
    text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.38);
    font-weight: bold;
  }
  &__unban-button {
    background: #7685b1;
    border-radius: 5px;
  }
  &__ban-button {
    background: #0c4593;
    border-radius: 5px;
  }

  &__reset-button {
    cursor: pointer;
    background: #0c4593;
    border-radius: 5px;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-bottom: 1rem;
    text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.38);
    font-weight: bold;
  }

  &__button-text {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.1875rem;
    color: #ffffff;
  }
  &__button-text2 {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.1875rem;
    color: #0b4694;
  }
}
