.paginator {
  user-select: none;
  width: fit-content;
  height: 2.5625rem;
  display: flex;
  align-items: center;
  background: #162348;
  border: 1px solid #087dff;

  &__arrow-container {
    cursor: pointer;
    height: 100%;
    width: 2.5625rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0c4593;
  }
  &__arrow {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-svg {
      width: 1rem;
      height: 1rem;
    }
  }
  &__current {
    height: 100%;
    width: 2.5625rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
