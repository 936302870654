.input-search-mobile {
  user-select: none;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 0.625rem 0 1rem;
  background: #ffffff;
  border: 0.125rem solid rgba(255, 255, 255, 0.3);
  border-radius: 0.25rem;
  position: relative;
  overflow: hidden;

  &__input {
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    outline: none;
    border: none;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: #0b4694;
    font-family: 'Roboto', sans-serif;
    -webkit-appearance: none;
    background-color: transparent;

    &::placeholder {
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.0625rem;
      color: #0b4694;
    }
    &::-webkit-search-cancel-button {
      opacity: 0;
      width: 0 !important;
      min-height: 0 !important;
      /* cursor: pointer;
      margin: 0 0.3125rem 0 0.625rem !important;
      min-width: 2.5rem !important;
      min-height: 2.5rem !important; */
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus#{&}:-webkit-autofill,
    &:-webkit-autofill::first-line,
    &::-internal-input-seggested {
      /* font: 1.25rem 'Product Sans', sans-serif !important; */ // рофланебало от разрабов хрома - захардкодили стили фонта ради безопасности - https://bugs.chromium.org/p/chromium/issues/detail?id=953689
      color: #0b4694;
      -webkit-text-fill-color: #0b4694;
      -webkit-box-shadow: 0 0 0px 1000px transparent inset;
      transition: background-color 5000s ease-in-out 0s;
      background: -webkit-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(0, 174, 255, 0.04) 50%,
        rgba(255, 255, 255, 0) 51%,
        rgba(0, 174, 255, 0.03) 100%
      );
    }
  }

  &__search-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.4375rem 0 0;

    &-svg {
      width: 1rem;
      height: 1rem;
    }
  }
  &__cross {
    z-index: 1;
    position: absolute;
    right: 0.6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-svg {
      width: 1rem;
      height: 1rem;
    }
  }
}
