.notification {
  display: block;
  width: 100%;
  min-height: 4rem;
  position: relative;
  z-index: 100;
  margin-top: 1rem;
  color: #0b4694;
  font-size: 0.875rem;
  font-weight: bold;
  animation: 0.7s ease init;
  border-radius: 5px;

  &_deleting {
    animation: 0.7s ease delete;
  }

  &__img-error {
    position: absolute;
    width: 2.6rem;
    left: 0.7rem;
    top: 1.2rem;
  }

  &__img-success {
    position: absolute;
    width: 2.6rem;
    left: 0.7rem;
    top: 1.2rem;
  }

  &__img-default {
    position: absolute;
    width: 2.6rem;
    left: 0.7rem;
    top: 1.2rem;
  }

  &__left {
    position: absolute;
    right: 0;
    top: 0.375rem;
  }

  &__cross {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &-svg {
      width: 0.75rem;
    }
  }

  &__text {
    flex-grow: 2;

    padding: 1.1875rem 0.875rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 4rem;
    word-break: break-word;
    font-weight: bold;
    font-size: 1.125rem;
    line-height: 1.3125rem;
    text-align: center;
    letter-spacing: 0.03em;
    color: #0b4694;
  }

  &__wrapper {
    width: 103%;
    height: min-content;
    display: flex;

    padding: 0.2rem 0;
  }

  &__wrapper_type {
    &_default {
      background: #ffffff;
      border: 0.125rem solid rgba(255, 255, 255, 0.3);
      box-shadow: 0rem 0.25rem 0.25rem rgba(11, 70, 148, 0.2);
      border-radius: 0.25rem;
      color: #0b4694;
    }

    /* &_default::before {
      content: "";
      position: absolute;
      width: 3.8rem;
      height: 5rem;
      top: 0px;
      background: rgba(255, 199, 0, 0.2);
      border-radius: 0.5rem 2rem 2rem 0.5rem;
    } */

    &_error {
      background: #ffffff;
      border: 0.125rem solid rgba(255, 255, 255, 0.3);
      box-shadow: 0rem 0.25rem 0.25rem rgba(11, 70, 148, 0.2);
      border-radius: 0.25rem;
      color: #0b4694;
    }

    /* &_error::before {
      content: "";
      position: absolute;
      width: 3.8rem;
      height: 5rem;
      top: 0px;
      background: rgba(226, 76, 75, 0.1);
      border-radius: 0.5rem 2rem 2rem 0.5rem;
    } */

    &_success {
      background: #ffffff;
      border: 0.125rem solid rgba(255, 255, 255, 0.3);
      box-shadow: 0rem 0.25rem 0.25rem rgba(11, 70, 148, 0.2);
      border-radius: 0.25rem;
      color: #0b4694;
    }

    /* &_success::before {
      content: "";
      position: absolute;
      width: 3.8rem;
      height: 5rem;
      top: 0px;
      background: rgba(163, 209, 102, 0.1);
      border-radius: 0.5rem 2rem 2rem 0.5rem;
    } */
  }

  @keyframes init {
    0% {
      opacity: 0;
      transform: translateX(10rem);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes delete {
    0% {
      opacity: 1;
      height: 4rem;
    }
    70% {
      opacity: 0.5;
      transform: translateX(120%);
      height: 4rem;
    }
    100% {
      opacity: 0;
      transform: translateX(200%);
      height: 0;
    }
  }
}
