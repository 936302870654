.total {
  padding: 1.6875rem 1.1875rem 2.5rem 1.1875rem;
  color: #0b4694;

  &__title {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 0 0 1.0625rem 0;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.1875rem;
    color: #0b4694;
  }

  &__input-block {
    width: 100%;
    margin-bottom: 1.125rem;
  }
  &__label {
    margin-bottom: 0.625rem;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: #0b4694;
  }
  &__input {
    width: 100%;
    height: 2.625rem;
  }

  &__bottom {
    width: 70%;
    height: 2.625rem;
    min-width: 15rem;
    margin: 2.5625rem auto 2.875rem auto;
  }
  &__button-text {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.1875rem;
    color: #ffffff;
  }

  &__result {
    margin: 0 auto;
    width: 94%;
    min-width: 20rem;
  }
  &__result-header {
    // background: linear-gradient(to bottom,#0e70b9 0,#005693 100%);
    height: 2.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.3125rem;
    padding: 0 0.8125rem;
    background: #ffffff;
    border: 0.125rem solid rgba(255, 255, 255, 0.3);
    border-radius: 0.25rem;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.0625rem;
  }
  &__row {
    height: 2.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    padding: 0 0.8125rem;
    background: #ffffff;
    border: 0.125rem solid rgba(255, 255, 255, 0.3);
    border-radius: 0.25rem;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.0625rem;
  }
  &__td {
    width: 25%;
    text-align: center;

    &_type {
      &_left {
        text-align: left;
        overflow: auto;
        /* text-overflow: ellipsis; */
      }
      &_right {
        /* text-align: right; */
      }
    }

    &_color {
      &_default {
        color: #0b4694;
      }
      &_green {
        color: #11ae7d;
      }
      &_red {
        color: #f50000;
      }
    }
  }
}
