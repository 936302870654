.create-user {
  width: 100%;
  font-size: 0.875rem !important;
  color: white;
  padding: 1.6875rem 0 0 0;

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1px;
    margin-bottom: 1.0625rem;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.1875rem;
    color: #0b4694;
  }

  &__wrapper {
    width: 93%;
    min-width: 20rem;
    margin: 0 auto;
  }

  &__title {
    margin-bottom: 0.625rem;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: #0b4694;
  }
  &__item {
    margin-bottom: 1.125rem;
  }
  &__button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.7143rem;
    width: 70%;
    min-width: 15rem;
    margin: 2.5rem auto 0 auto;
  }
  &__button-text {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.1875rem;
    color: #ffffff;
  }
}
