.locale {
  width: 100%;
  padding: 2.75rem 0.8125rem 2.5rem 0.8125rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    width: 100%;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.5rem;
    background: #ffffff;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.1875rem;
    color: #0b4694;
    position: relative;
  }
  &__arrow {
    position: absolute;
    right: 0.9375rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: rotate(180deg);

    &-svg {
      width: 1rem;
      height: 1rem;
    }
  }
  &_opened &__arrow {
    transform: rotate(0deg);
  }

  &__item {
    width: 90%;
    height: 2.5625rem;
    display: flex;
    align-items: center;
    padding: 0 0 0 0.7rem;
    margin-bottom: 0.25rem;
    background: #ffffff;
    border: 0.125rem solid rgba(255, 255, 255, 0.3);
    border-radius: 0.25rem;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: #0b4694;

    &_active {
      background: linear-gradient(135deg, #1474e9 0%, #025bc2 100%);
      color: #ffffff;
    }
  }
  &__image {
    width: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 0.8125rem;
  }
  &__bottom {
    width: 15rem;
    margin-top: 6.3125rem;
  }
  &__button {
    width: 100%;
    height: 2.625rem;
  }
  &__button-text {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.1875rem;
    color: #ffffff;
  }
}
