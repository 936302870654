.transaction-item {
  height: 2.5rem;
  display: grid;
  grid-template-columns: 1.3fr 1.3fr 1fr 1fr;
  align-items: center;
  margin-bottom: 1rem;
  padding: 0 1rem;
  background: #ffffff;
  border: 0.125rem solid rgba(255, 255, 255, 0.3);
  border-radius: 0.25rem;

  &__column-amount {
    color: #c22326;
  }
  &__column,
  &__column-amount {
    width: 100%;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: #0b4694;
    overflow: auto;

    &_type {
      &_center {
        text-align: center;
      }
      &_right {
        text-align: right;
      }
    }
    &_colored {
      color: #00890d;
    }
  }
}
