.button-mobile {
  user-select: none;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.5rem;
  border: none;
  outline: none;
  user-select: none;
  font-weight: 700;
  font-size: 1rem;
  color: white;
  font-family: 'Rubik', sans-serif;
  text-align: center;
  white-space: nowrap;

  &:active {
    transform: translateY(2px);
  }

  &_borderRadius {
    &_default {
      border-radius: 0.25rem;
    }
  }

  &_color {
    &_default {
      background: linear-gradient(135deg, #1474e9 0%, #025bc2 100%);
    }
    &_white {
      background: #ffffff;
    }
  }
  // &:hover {
  //   background: #f8df37;
  //   box-shadow: 0px 0px 0.3125rem #f8df37;
  // }
  // &:hover#{&}_color {
  //   &_default {
  //     background: linear-gradient(0deg, #ff8e01, #ff8e01),
  //       linear-gradient(135deg, #e98714 0%, #c24702 100%), #087fff;
  //     box-shadow: 0px 0px 0.9375rem #c24702;
  //   }
  // }

  &_disabled {
    // background-color: gray;
    cursor: not-allowed;
    box-shadow: none;
  }
}
