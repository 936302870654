.footer {
  width: 95%;
  min-width: 20.75rem;
  display: flex;
  flex-direction: column;
  margin: auto auto 4.875rem auto;

  &__title {
    width: 100%;
    height: 2.5625rem;
    display: flex;
    align-items: center;
    margin-bottom: 0.5625rem;
    padding-left: 1.25rem;
    background: #ffffff;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.1875rem;
    color: #0b4694;
  }

  &__item {
    cursor: pointer;
    width: 91%;
    min-width: 18.875rem;
    height: 2.75rem;
    display: flex;
    align-items: center;
    margin: 0 auto 0.25rem auto;
    background: #ffffff;
    border: 0.125rem solid rgba(255, 255, 255, 0.3);
    border-radius: 0.25rem;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: #0b4694;
  }
  &__icon-container {
    height: 100%;
    width: 3rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 0.9375rem 0 0;
  }
  &__icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-svg {
      width: 100%;
      height: 100%;
    }
  }
}
