.change-pas {
  width: 100%;
  padding: 2.875rem 1.125rem 2.5rem 1.125rem;
  color: white;

  &__title {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 1.0625rem;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.1875rem;
    color: #0b4694;
  }

  &__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__field {
    width: 100%;
    &:not(:last-child) {
      margin: 0 0 1.125rem 0;
    }
  }
  &__field-title {
    margin: 0 0 0.625rem 0;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: #0b4694;
  }
  &__field-input {
    height: 2.625rem;
  }

  &__button {
    width: 80%;
    height: 2.625rem;
    margin-top: 2.5rem;
  }
}
