.auth {
  padding: 1.6875rem 1.1875rem 2.5rem 1.1875rem;
  color: white;

  &__title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.875rem;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.1875rem;
    color: #0b4694;
  }

  &__icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.875rem;

    &-svg {
      width: 11.125rem;
      height: 11.5rem;
    }
  }

  &__input-block {
    width: 100%;
    margin: 0 auto 1.0625rem auto;
  }
  &__image {
    width: 1.4rem;
    margin-right: 0.5rem;
  }
  &__bottom {
    margin: 2.5rem 0 0 0;
    padding: 0.7em 1em;
  }
  &__button {
    width: 69%;
    min-width: 15rem;
    max-width: 83rem;
    height: 2.625rem;
    margin: 0 auto;
  }
  &__button-text {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.1875rem;
    color: #ffffff;
  }
  &__ok {
    width: 1.8rem;
    position: absolute;
    right: 1rem;
  }
  &__label {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.1875rem;
    align-items: center;
    color: #0b4694;
    margin-bottom: 0.5625rem;
  }
}
