.select {
  width: 100%;
  min-height: 2.2em;
  text-align: left;
  outline: 0;
  padding: 0.5rem;
  border: 1px solid #ddd;
  font-size: 1rem;
  position: relative;
  background-color: #fff;

  &__item {
    margin: 0 0.5rem;
    padding: 0.5rem 0;
    border-bottom: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_current {
      margin: 0;
      border-bottom: none;
      padding: 0;

    }

    &:last-child {
      border-bottom: none;
    }
  }

  &__arrow {
    padding: 0 0.5rem;
    & * {
      fill: #000;
    }

    &-svg {
      width: 0.5rem;
      transform: rotate(90deg);
    }
  }

  &__items-list {
    position: absolute;
    top: 2.2em;
    left: -1px;
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 100;
    background-color: #fff;
    border: 1px solid #ddd;
  }
}