.users-list {
  padding: 1.9375rem 0 2.5rem 0;
  color: #0b4694;

  &__filters {
    width: 100%;
    margin: 0 auto 3.125rem;
    position: relative;
    padding: 0 1.875rem;
  }
  &__filters-header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 1.4375rem 0;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.1875rem;
    color: #0b4694;
  }
  &__filters-search {
    width: 100%;
    height: 2.5625rem;
    display: flex;
    position: relative;
  }

  &__subheader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-width: 20rem;
    margin: 0 0 1.25rem 0;
    padding: 0 1.3125rem;
  }
  &__subheader-item {
    width: 48%;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    background: #ffffff;
    border: 0.125rem solid rgba(255, 255, 255, 0.3);
    border-radius: 0.25rem;
  }
  &__subheader-text1 {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: #0b4694;
  }
  &__subheader-text2 {
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: #0b4694;
  }

  &__list {
    padding: 0 1.875rem;
  }
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 2.5rem;
    margin: 0 0 0.25rem 0;
    padding: 0 1rem 0 1.5625rem;
    background: #ffffff;
    border: 0.125rem solid rgba(255, 255, 255, 0.3);
    border-radius: 0.25rem;

    &-nick {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.0625rem;
      color: #0b4694;
    }
    &-right {
      display: flex;
      align-items: center;
    }
    &-balance {
      margin-right: 0.8125rem;
      font-weight: 700;
      font-size: 0.875rem;
      line-height: 1.0625rem;
      text-align: right;
      color: #0b4694;
    }
  }
  &__item-arrow {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-svg {
      width: 1rem;
      height: 1rem;
    }
  }

  &__item-nick {
    &_banned {
      color: red;
    }
  }
}
