@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

#root,
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Rubik", sans-serif;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
  color: inherit;
}

html {
  font-size: 16px;
}

body {
  flex-grow: 2;
  display: flex;
  flex-direction: column;
}

.app {
  flex-grow: 2;
  width: 100%;
  /* height: 100%; */
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background: #c9e3ff;
}
