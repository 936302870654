.tree-row {
  width: fit-content;
  margin-bottom: 0.2rem;
  white-space: nowrap;

  &__username {
    color: #0b4694;
    margin-right: 0.5rem;
    margin-left: 1rem;

    &_banned {
      color: red;
    }
  }
  &__childrens {
    padding-left: 2%;
  }
  &__parent {
    cursor: pointer;
    margin-bottom: 0.7rem;
    display: flex;
    align-items: center;
  }
  &__td {
    display: flex;
    align-items: center;
  }
  &__arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.25s;
    transform: rotate(-90deg);
    margin-right: -0.7rem;

    &_opened {
      transform: rotate(0);
    }
    &-svg {
      width: 0.7rem;
    }
  }
  &__ban {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.9375rem 0 0.5rem;
    * {
      fill: red;
    }
    &_banned {
      * {
        fill: green;
      }
    }
    &-svg {
      width: 1rem;
    }
  }
}
