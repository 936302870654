.subheader {
  width: 100%;
  height: 2.8125rem;
  min-height: 2.8125rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  color: #0b4694;
  padding: 0 0.9375rem 0 1.25rem;

  &__home {
    width: 1.5rem;
    height: 1.5rem;
  }
  &__image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-svg {
      width: 2.0625rem;
      height: 2.0625rem;
    }
  }
}
